import { React, Fragment, useState } from "react";
import Userfront from "@userfront/react";

const EditPermissions = ({ owner }) => {
    const [filePermission, setFilePermission] = useState(owner.leyfiskjol);
    const [neighborsPermission, setNeighborsPermission] = useState(owner.leyfigrannar);
    const [drawPermission, setDrawPermission] = useState(owner.leyfiteikna);

    const updatePermissions = async (e) => {
        e.preventDefault();

        try {
            console.log(owner);
            console.log(filePermission);
            console.log(neighborsPermission);
            console.log(drawPermission);

            const body = { leyfiskjol: filePermission, leyfigrannar: neighborsPermission, leyfiteikna: drawPermission };

            await fetch(`https://min.eignamork.is/api/permissions/${owner.eigandiid}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${Userfront.accessToken()}`
                },
                body: JSON.stringify(body)
            });

            window.location = "/eigendur";
        } catch (err) {
            console.error(err.message);
        }
    }

    return (
        <Fragment>
            <input type="button" value="Breyta" className="btn btn-warning" data-toggle="modal" data-target={`#id${owner.eigandiid}`} />

            <div className="modal" id={`id${owner.eigandiid}`}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Breyta leyfum eiganda</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">
                            <div className="form-check">

                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="filePermission"
                                    defaultChecked={filePermission}
                                    onChange={e => setFilePermission(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Skjöl
                                </label>
                            </div>
                        </div>

                        <div className="modal-body">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultChecked={neighborsPermission}
                                    id="neighborsPermission"
                                    onChange={e => setNeighborsPermission(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Nágrannar
                                </label>
                            </div>
                        </div>

                        <div className="modal-body">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultChecked={drawPermission}
                                    id="drawPermission"
                                    onChange={e => setDrawPermission(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Teikna
                                </label>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <input type="button" value="Loka" className="btn btn-secondary" data-dismiss="modal" />
                            <input type="button" value="Vista breytingar" className="btn btn-primary" onClick={e => updatePermissions(e)} />
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default EditPermissions;