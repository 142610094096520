import { React, Fragment, useEffect, useState } from "react";
import { pdfjs } from 'react-pdf';
import { AiOutlineDownload } from 'react-icons/ai';
import { Route, Redirect } from "react-router-dom";
import Userfront from "@userfront/react";

import EditPdf from "./EditPdf";

const PdfList = () => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [pdfs, setPdfs] = useState([]);

    const getPdfs = async () => {
        try {
            const response = await fetch("https://min.eignamork.is/api/pdfs", {
                headers: {
                    Authorization: `Bearer ${Userfront.accessToken()}`
                }
            })

            const jsonData = await response.json();

            setPdfs(jsonData);
        } catch (err) {
            console.error(err.message);
        }
    }

    // Delete pdf function
    const deletePdf = async (id) => {
        try {
            await fetch(`https://min.eignamork.is/api/pdfs/${id}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${Userfront.accessToken()}`
                }
            });

            setPdfs(pdfs.filter(pdf => pdf.skjalid !== id));
        } catch (err) {
            console.error(err.message);
        }
    }

    useEffect(() => {
        getPdfs();
    }, [])

    function renderFn({ location }) {
        // If the user is not logged in, redirect to login
        if (!Userfront.accessToken()) {
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: location },
                    }}
                />
            );
        }

        return (
            <Fragment>
                <h1 className="mt-5">Skjalakerfi</h1>
                <table className="table mt-3 text-center sortable">
                    <thead>
                        <tr>
                            <th>Landeignanúmer</th>
                            <th>Skjalgerð</th>
                            <th className="date">Innlesið</th>
                            <th>Skýring</th>
                            <th>Skjal</th>
                            <th className="changePdf">Breyta</th>
                            <th className="deletePdf">Eyða</th>
                        </tr>
                        <tr>

                        </tr>
                    </thead>
                    <tbody>
                        {pdfs.map(pdf => (
                            <tr key={pdf.skjalid}>
                                <td>L{pdf.landnr}</td>
                                <td>{pdf.nafn}</td>
                                <td className="date">{pdf.dags.split('T')[0]}</td>
                                <td>{pdf.skyring}</td>
                                <td>
                                    <div>
                                        <button id="downloadButton" type="button" className="btn">
                                            <a href={`data:application/pdf;base64,${pdf.pdfdoc}`} download="eignamork.pdf">
                                                <AiOutlineDownload />
                                            </a>
                                        </button>
                                    </div>
                                </td>
                                <td className="changePdf">
                                    <EditPdf pdf={pdf} />
                                </td>
                                <td className="deletePdf">
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => {
                                            deletePdf(pdf.skjalid)
                                        }
                                        }
                                    >
                                        Eyða
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Fragment>
        )
    }

    return <Route render={renderFn} />;
}

export default PdfList;