import { React, Fragment, useEffect, useState } from "react";
import Userfront from "@userfront/react";
import { Route, Redirect } from "react-router-dom";

import EditLand from "./EditLand";

const LandsList = () => {
    const [lands, setLands] = useState([]);

    // Delete land function
    const deleteLand = async (id) => {
        try {
            await fetch(`https://min.eignamork.is/api/lands/${id}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${Userfront.accessToken()}`
                }
            });

            setLands(lands.filter(land => land.landeignid !== id));
        } catch (err) {
            console.error(err.message);
        }
    }

    const getLands = async () => {
        try {
            const response = await fetch("https://min.eignamork.is/api/lands", {
                headers: {
                    Authorization: `Bearer ${Userfront.accessToken()}`
                }
            })
            const jsonData = await response.json();

            setLands(jsonData);
        } catch (err) {
            console.error(err.message);
        }
    }

    useEffect(() => {
        getLands();
    }, [])

    function renderFn({ location }) {
        // If the user is not logged in, redirect to login
        if (!Userfront.accessToken()) {
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: location },
                    }}
                />
            );
        }

        return (
            <Fragment>
                <div className="mt-5">
                    <h1>Landeignir</h1>
                </div>
                <table className="table mt-3 text-center">
                    <thead>
                        <tr>
                            <th>Landeignanúmer</th>
                            <th>Heiti</th>
                            <th className="date">Dags skráð</th>
                            <th>Breyta</th>
                            <th>Eyða</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lands.map(land => (
                            <tr key={land.landeignid}>
                                <td>L{land.landnr}</td>
                                <td>{land.nafnlandeignar}</td>
                                <td className="date">{land.dags.split('T')[0]}</td>
                                <td>
                                    <EditLand land={land} />
                                </td>
                                <td>
                                    <input
                                        type="button"
                                        value="Eyða"
                                        className="btn btn-danger"
                                        onClick={() => {
                                            deleteLand(land.landeignid)
                                        }
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Fragment>
        )
    }

    return <Route render={renderFn} />;
}

export default LandsList;