import { React, Fragment, useEffect, useState } from "react";
import Userfront from "@userfront/react";
import { Route, Redirect } from "react-router-dom";
import { FcCheckmark } from 'react-icons/fc';
import { BsDash } from 'react-icons/bs';

import EditPermissions from "./EditPermissions";

const OwnersList = () => {
    const [owners, setOwners] = useState([]);

    const getOwners = async () => {
        try {
            const response = await fetch("https://min.eignamork.is/api/owners", {
                headers: {
                    Authorization: `Bearer ${Userfront.accessToken()}`
                }
            })
            const jsonData = await response.json();

            setOwners(jsonData);
        } catch (err) {
            console.error(err.message);
        }
    }

    useEffect(() => {
        getOwners();
    }, [])

    function renderFn({ location }) {
        // If the user is not logged in, redirect to login
        if (!Userfront.accessToken()) {
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: location },
                    }}
                />
            );
        }

        return (
            <Fragment>
                <div className="mt-5">
                    <h1>Landeigendur</h1>
                </div>
                <table className="table mt-3 text-center">
                    <thead>
                        <tr>
                            <th>Nafn</th>
                            <th>Kennitala</th>
                            <th>Heimilisfang</th>
                            <th>Skjöl</th>
                            <th>Grannar</th>
                            <th>Teikna</th>
                            <th>Breyta</th>
                        </tr>
                    </thead>
                    <tbody>
                        {owners.map(owner => (
                            <tr key={owner.eigandiid}>
                                <td>{owner.nafn}</td>
                                <td>{owner.kennitala}</td>
                                <td>{owner.heimilisfang}</td>
                                {owner.leyfiskjol ?
                                    <td><FcCheckmark /></td>
                                    : <td><BsDash /></td>
                                }
                                {owner.leyfigrannar ?
                                    <td><FcCheckmark /></td>
                                    : <td><BsDash /></td>
                                }
                                {owner.leyfiteikna ?
                                    <td><FcCheckmark /></td>
                                    : <td><BsDash /></td>
                                }
                                <td>
                                    <EditPermissions owner={owner} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Fragment >
        )
    }

    return <Route render={renderFn} />;
}

export default OwnersList;