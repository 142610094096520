import { React, Fragment, useState } from "react";
import Userfront from "@userfront/react";

const EditLand = ({ land }) => {
    const [landnr, setLandnr] = useState(land.landnr);
    const [heiti, setHeiti] = useState(land.nafnlandeignar);
    const [landnrError, setLandnrError] = useState("");
    const [heitiError, setHeitiError] = useState("");

    const handleValidation = () => {
        var landnrError;
        var heitiError;

        var regex = /^[A-Za-z0-9ðíóæöéá ]+$/
        var regexNumber = /^[0-9]*$/

        // Validate landnr
        if (landnr.trim().length !== 6) {
            landnrError = "Landeignanúmer þarf að vera 6 tölustafir";
        }

        if (!regexNumber.test(landnr)) {
            landnrError = "Landeignanúmer má eingöngu innihalda tölustafi";
        }

        if (landnr.trim() === '') {
            landnrError = "Landeignanúmer má ekki vera tómt";
        }

        // Validate heiti
        if (!regex.test(heiti)) {
            heitiError = "Ólöglegir stafir";
        }

        if (heiti.trim() === '') {
            heitiError = "Heiti má ekki vera tómt";
        }

        if (landnrError || heitiError) {
            if (landnrError) {
                setLandnrError(landnrError);
            }

            if (heitiError) {
                setHeitiError(heitiError);
            }

            return false;
        }

        return true;
    }

    const updateLand = async (e) => {
        e.preventDefault();
        setLandnrError("");
        setHeitiError("");

        if (handleValidation()) {
            try {
                const body = { Landnr: parseInt(landnr, 10), Heiti: heiti };

                await fetch(`https://min.eignamork.is/api/lands/${land.landeignid}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Userfront.accessToken()}`
                    },
                    body: JSON.stringify(body)
                });

                window.location = "/landeignir";
            } catch (err) {
                console.error(err.message);
            }
        }
    }

    return (
        <Fragment>
            <input type="button" value="Breyta" className="btn btn-warning" data-toggle="modal" data-target={`#id${land.landeignid}`} />

            <div className="modal" id={`id${land.landeignid}`}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Breyta landeign</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={() => setLandnr(land.landnr)}>&times;</button>
                        </div>

                        <div className="modal-body">
                            <label htmlFor="landnr" className="col-form-label">
                                Landeignanúmer*
                            </label>
                            <input
                                id="landnr"
                                type="number"
                                maxLength="6"
                                className="form-control"
                                value={landnr}
                                onChange={e => setLandnr(e.target.value)}
                            />
                            {landnrError}
                        </div>

                        <div className="modal-body">
                            <label htmlFor="name" className="col-form-label">
                                Heiti*
                            </label>
                            <input id="name" type="text" className="form-control" value={heiti} onChange={e => setHeiti(e.target.value)} />
                            {heitiError}
                        </div>

                        <div className="modal-footer">
                            <input type="button" value="Loka" className="btn btn-secondary" data-dismiss="modal" onClick={() => setLandnr(land.landnr)} />
                            <input type="button" value="Vista breytingar" className="btn btn-primary" onClick={e => updateLand(e)} />
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default EditLand;