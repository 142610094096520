import './App.css';
import { React, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Userfront from "@userfront/react";

// components
import LandsList from "./components/LandsList";
import NavigationBar from "./components/NavigationBar";
import PdfList from "./components/PdfList";
import Footer from "./components/Footer";
import LoginForm from "./components/LoginForm";
import CreatePdf from "./components/CreatePdf";
import CreateLand from "./components/CreateLand";
import OwnersList from "./components/OwnersList";
import ConnectNeighbors from "./components/ConnectNeighbors";

Userfront.init("vndpgvn7");

function App() {
  return (
    <Fragment>
      <div className="container-xxl">
        <Router>
          <div>
            <Switch>
              <Route path="/login">
                <NavigationBar />
                <div className="container">
                  <Login />
                </div>
                <Footer />
              </Route>
              <Route path="/landeignir/add">
                <NavigationBar />
                <div className="container my-5">
                  <CreateLand />
                </div>
                <Footer />
              </Route>
              <Route path="/landeignir">
                <NavigationBar />
                <div className="container">
                  <LandsList />
                </div>
                <Footer />
              </Route>
              <Route path="/skjol/add">
                <NavigationBar />
                <div className="container my-5">
                  <CreatePdf />
                </div>
                <Footer />
              </Route>
              <Route path="/skjol">
                <NavigationBar />
                <div className="container my-5">
                  <PdfList />
                </div>
                <Footer />
              </Route>
              <Route path="/eigendur">
                <NavigationBar />
                <div className="container my-5">
                  <OwnersList />
                </div>
                <Footer />
              </Route>
              <Route path="/nagrannar/add">
                <NavigationBar />
                <div className="container my-5">
                  <ConnectNeighbors />
                </div>
                <Footer />
              </Route>
              <Route path="/">
                <NavigationBar />
                <div className="container">
                  <LandsList />
                </div>
                <Footer />
              </Route>
            </Switch>
          </div>
        </Router>
      </div >
    </Fragment >
  );
}

function Login() {
  return (
    <div className="container my-5">
      <LoginForm />
    </div>
  );
}

export default App;
