import React, { Component, Fragment } from 'react';
import Userfront from "@userfront/react";
import Alert from "./Alert";

class CreateLand extends Component {
    constructor(props) {
        super(props);
        this.state = {
            landnr: "",
            landnrError: "",
            heiti: "",
            heitiError: "",
            owners: [],
            selectedOwnerId: ""
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        event.preventDefault();
        const target = event.target;
        this.setState({
            [target.name]: target.value,
        });
    }

    async getOwners() {
        try {
            const response = await fetch("https://min.eignamork.is/api/owners", {
                headers: {
                    Authorization: `Bearer ${Userfront.accessToken()}`
                }
            })

            const jsonData = await response.json();

            this.setState({ owners: jsonData });
            this.setState({ selectedOwnerId: jsonData[0]["eigandiid"] });
        } catch (err) {
            console.error(err.message);
        }
    }

    componentDidMount() {
        this.getOwners();
    }

    dropdownChangedOwners = (e) => {
        e.preventDefault();
        this.setState({ selectedOwnerId: e.target.value });
    }

    handleValidation() {
        const { landnr, heiti } = this.state;
        var landnrError;
        var heitiError;

        var regex = /^[A-Za-z0-9ðíóæöéá ]+$/
        var regexNumber = /^[0-9]*$/

        if (landnr.trim().length !== 6) {
            landnrError = "Landeignanúmer þarf að vera 6 tölustafir";
        }

        if (!regexNumber.test(landnr)) {
            landnrError = "Landeignanúmer má eingöngu innihalda tölustafi";
        }

        // Validate landnr
        if (landnr.trim() === '') {
            landnrError = "Landeignanúmer má ekki vera tómt";
        }

        // Validate heiti
        if (!regex.test(heiti)) {
            heitiError = "Ólöglegir stafir";
        }

        if (heiti.trim() === '') {
            heitiError = "Heiti má ekki vera tómt";
        }

        if (landnrError || heitiError) {
            if (landnrError) {
                this.setState({ landnrError: landnrError });
            }

            if (heitiError) {
                this.setState({ heitiError: heitiError });
            }

            return false;
        }

        return true;
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ landnrError: "", heitiError: "" });

        if (this.handleValidation()) {
            try {
                const body = { EigandiID: this.state.selectedOwnerId, Landnr: this.state.landnr, Heiti: this.state.heiti };
                await fetch("https://min.eignamork.is/api/lands", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Userfront.accessToken()}`
                    },
                    body: JSON.stringify(body)
                })

                window.location = "/landeignir";
            } catch (err) {
                console.error(err.message);
            }
        }
    }

    render() {
        return (
            <Fragment>
                <div className="formDiv">
                    <h1 className="mb-3">Bæta við landeign</h1>
                    <Alert message={this.state.alertMessage} />
                    <select
                        value={this.state.selectedOwnerId}
                        onChange={this.dropdownChangedOwners}
                        className="form-select float-right mb-3 d-inline mx-1"
                        aria-label="Owner select"
                    >
                        {this.state.owners.map(owner => (
                            <option key={owner.eigandiid} value={owner.eigandiid}>{owner.kennitala} - {owner.nafn}</option>
                        ))}
                    </select>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <label>
                                Landeignanúmer*
                                <input
                                    className="form-control"
                                    name="landnr"
                                    type="text"
                                    maxLength="6"
                                    value={this.state.landnr}
                                    onChange={this.handleInputChange}
                                />
                            </label>
                            {this.state.landnrError}
                        </div>
                        <div className="form-group">
                            <label>
                                Heiti*
                                <input
                                    className="form-control"
                                    maxLength="40"
                                    name="heiti"
                                    type="text"
                                    value={this.state.heiti}
                                    onChange={this.handleInputChange}
                                />
                            </label>
                            {this.state.heitiError}
                        </div>
                        <input type="submit" value="Bæta við" className="btn btn-success mt-2" />
                    </form>
                </div>
            </Fragment>
        );
    }
}

export default CreateLand;