import React, { Component, Fragment } from 'react';
import Userfront from "@userfront/react";
import Alert from "./Alert";

class ConnectNeighbors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedNeighborId1: "",
            selectedNeighborId2: "",
            lands: [],
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.setAlertMessage = this.setAlertMessage.bind(this);
    }

    async getLands() {
        try {
            const response = await fetch("https://min.eignamork.is/api/lands", {
                headers: {
                    Authorization: `Bearer ${Userfront.accessToken()}`
                }
            })

            const jsonData = await response.json();

            this.setState({ lands: jsonData });

            if (jsonData.length > 2) {
                this.setState({ selectedNeighborId1: jsonData[0]["landeignid"] });
                this.setState({ selectedNeighborId2: jsonData[1]["landeignid"] });
            }
        } catch (err) {
            console.error(err.message);
        }
    }

    componentDidMount() {
        this.getLands();
    }

    dropdownChanged1 = (e) => {
        e.preventDefault();
        this.setState({ selectedNeighborId1: parseInt(e.target.value, 10) });
    }

    dropdownChanged2 = (e) => {
        e.preventDefault();
        this.setState({ selectedNeighborId2: parseInt(e.target.value, 10) });
    }

    handleValidation() {
        if (this.state.selectedNeighborId1 === this.state.selectedNeighborId2) {
            this.setAlertMessage("Landeign getur ekki verið sinn eigin nágranni.");
            return false;
        }

        return true;
    }

    async handleSubmit(event) {
        event.preventDefault();

        // Reset the alert to empty
        this.setAlertMessage();

        if (this.handleValidation()) {
            try {
                const body = { Landnr1: this.state.selectedNeighborId1, Landnr2: this.state.selectedNeighborId2 };
                await fetch("https://min.eignamork.is/api/neighbors", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Userfront.accessToken()}`
                    },
                    body: JSON.stringify(body)
                }).then((res) => {
                    if (res.ok) {
                        window.location = "/landeignir";
                    } else {
                        this.setAlertMessage("Landeignir hafa þegar verið skráðar nágrannar.");
                    }
                })
            } catch (err) {
                console.error(err.message);
            }
        }
    }

    setAlertMessage(message) {
        this.setState({ alertMessage: message });
    }

    render() {
        return (
            <Fragment>
                <div className="formDiv">
                    <h1 className="mb-3">Bæta við nágrannatengslum</h1>
                    <Alert message={this.state.alertMessage} />
                    <div className="form-group formDiv">
                        <label className="col-form-label">
                            Landeign 1*
                        </label>
                        <select
                            value={this.state.selectedNeighborId1}
                            onChange={this.dropdownChanged1}
                            className="form-select float-right mb-3 d-inline mx-1"
                            aria-label="Land select"
                        >
                            {this.state.lands.map(land =>
                                <option key={land.landeignid} value={land.landeignid}>{land.landnr} - {land.nafnlandeignar}</option>
                            )}
                        </select>
                    </div>
                    <div className="form-group formDiv">
                        <label className="col-form-label">
                            Landeign 2*
                        </label>
                        <select
                            value={this.state.selectedNeighborId2}
                            onChange={this.dropdownChanged2}
                            className="form-select float-right mb-3 d-inline mx-1"
                            aria-label="Land select"
                        >
                            {this.state.lands.map(land =>
                                <option key={land.landeignid} value={land.landeignid}>{land.landnr} - {land.nafnlandeignar}</option>
                            )}
                        </select>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <input type="submit" value="Vista breytingar" className="btn btn-primary mt-2" />
                    </form>
                </div>
            </Fragment>
        );
    }
}

export default ConnectNeighbors;