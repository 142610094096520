import { React, Fragment } from "react";
import Userfront from "@userfront/react";
import { Route } from "react-router-dom";
import { FaUserAlt } from 'react-icons/fa';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';

import eignamorkLogoLarge from '../images/eignamork.png';
import eignamorkLogoSmall from '../images/eignamork_small.png';

const NavigationBar = () => {
    const navDropdownTitle = (<FaUserAlt />);

    function renderFn({ location }) {
        // If the user is not logged in, redirect to login
        if (!Userfront.accessToken()) {
            return (
                <Fragment>
                    <Navbar bg="light" variant="light">
                        <Container>
                            <Navbar.Brand href="/">
                                <img id="largeLogo" src={eignamorkLogoLarge} alt="Eignamörk Logo" />
                                <img id="smallLogo" src={eignamorkLogoSmall} alt="Eignamörk Logo Small" />
                            </Navbar.Brand>
                            <Nav className="me-auto">
                                <NavDropdown title={navDropdownTitle}>
                                    <NavDropdown.Item href="/login">Innskráning</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Container>
                    </Navbar>
                </Fragment>
            );
        }

        // If the user is logged in, show the dashboard
        const userData = JSON.stringify(Userfront.user, null, 2);
        const parsedUserData = JSON.parse(userData);

        return (
            <Fragment>
                <Navbar bg="light" variant="light">
                    <Container>
                        <Navbar.Brand href="/">
                            <img id="largeLogo" src={eignamorkLogoLarge} alt="Eignamörk Logo" />
                            <img id="smallLogo" src={eignamorkLogoSmall} alt="Eignamörk Logo Small" />
                        </Navbar.Brand>
                        <Nav className="me-auto">
                            <NavDropdown title="Landeignir">
                                <NavDropdown.Item href="/landeignir">Skoða landeignir</NavDropdown.Item>
                                <NavDropdown.Item href="/landeignir/add">Bæta við</NavDropdown.Item>
                                <NavDropdown.Item href="/nagrannar/add">Skrá nágranna</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Skjöl">
                                <NavDropdown.Item href="/skjol">Skjöl</NavDropdown.Item>
                                <NavDropdown.Item href="/skjol/add">Bæta við</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/eigendur">Eigendur</Nav.Link>
                            <NavDropdown title={navDropdownTitle}>
                                <p className="mt-1 text-center">{parsedUserData["name"]}</p>
                                <NavDropdown.Item onClick={Userfront.logout}>Útskrá</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Container>
                </Navbar>
            </Fragment>
        )
    }

    return <Route render={renderFn} />;
}

export default NavigationBar;