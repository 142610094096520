import React, { Component, Fragment } from 'react';
import Userfront from "@userfront/react";

class CreatePdf extends Component {
    constructor(props) {
        super(props);
        this.state = {
            landnr: "",
            description: "",
            descriptionError: "",
            pdfFile: "",
            pdfFileError: "",
            fileType: ['application/pdf'],
            lands: [],
            selectedLandeignId: '',
            filetypes: [],
            selectedFileTypeId: ""
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange = (event) => {
        event.preventDefault();
        const target = event.target;
        this.setState({
            [target.name]: target.value,
        });
    }

    async getLands() {
        try {
            // If the user is logged in, show the dashboard
            const userData = JSON.stringify(Userfront.user, null, 2);
            const parsedUserData = JSON.parse(userData);
            const userID = parsedUserData.data["eigandiID"];

            if (userID) {
                const response = await fetch("https://min.eignamork.is/api/lands", {
                    headers: {
                        Authorization: `Bearer ${Userfront.accessToken()}`
                    }
                })

                const jsonData = await response.json();

                this.setState({ lands: jsonData });
                this.setState({ selectedLandeignId: jsonData[0]["landeignid"] });
            }
        } catch (err) {
            console.error(err.message);
        }
    }

    async getTypes() {
        try {
            // If the user is logged in, show the dashboard
            const userData = JSON.stringify(Userfront.user, null, 2);
            const parsedUserData = JSON.parse(userData);
            const userID = parsedUserData.data["eigandiID"];

            if (userID) {
                const response = await fetch("https://min.eignamork.is/api/filetypes", {
                    headers: {
                        Authorization: `Bearer ${Userfront.accessToken()}`
                    }
                })

                const jsonData = await response.json();

                this.setState({ filetypes: jsonData });
                this.setState({ selectedFileTypeId: jsonData[0]["skjalgerdid"] });
            }
        } catch (err) {
            console.error(err.message);
        }
    }

    componentDidMount() {
        this.getLands();
        this.getTypes();
    }

    dropdownChangedLands = (e) => {
        e.preventDefault();
        this.setState({ selectedLandeignId: e.target.value });
    }

    dropdownChangedFiletypes = (e) => {
        e.preventDefault();
        this.setState({ selectedFileTypeId: e.target.value });
    }

    // onchange event
    handlePdfFileChange = (e) => {
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile && this.state.fileType.includes(selectedFile.type)) {
                let reader = new FileReader();
                reader.readAsDataURL(selectedFile);
                reader.onloadend = (e) => {
                    this.setState({ pdfFile: e.target.result });
                    this.setState({ pdfFileError: "" });
                }
            }
            else {
                this.setState({ pdfFile: null });
                this.setState({ pdfFileError: 'Please select valid pdf file' });
            }
        }
        else {
            console.log('select your file');
        }
    }

    handleValidation() {
        const { description } = this.state;
        var descriptionError;

        var regex = /^[A-Za-z0-9ðíóæöéá ]+$/

        // Validate description
        if (!regex.test(description)) {
            descriptionError = "Ólöglegir stafir";
        }

        if (description.trim() === '') {
            descriptionError = "Skýring má ekki vera tóm";
        }

        if (descriptionError) {
            this.setState({ descriptionError: descriptionError });

            return false;
        }

        return true;
    }

    async handleSubmit(e) {
        e.preventDefault();

        this.setState({ descriptionError: "" });

        if (this.handleValidation()) {
            if (this.state.pdfFile !== null) {
                try {
                    const body = { pdf_file: this.state.pdfFile, skyring: this.state.description, skjalgerd_id: this.state.selectedFileTypeId };

                    await fetch(`https://min.eignamork.is/api/pdfs/` + this.state.selectedLandeignId, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(body)
                    });
                } catch (err) {
                    console.error(err.message);
                }
            }

            window.location = "/skjol";
        }
    }

    render() {
        return (
            <Fragment>
                <div className="formDiv">
                    <h1 className="mb-3">Bæta við PDF skjali</h1>
                    <div className="d-flex flex-row">
                        <select
                            value={this.state.selectedLandeignId}
                            onChange={this.dropdownChangedLands}
                            className="form-select float-right mb-3 d-inline mx-1"
                            aria-label="Landeignanúmer select"
                        >
                            {this.state.lands.map(land => (
                                <option key={land.landeignid} value={land.landeignid}>L {land.landnr} - {land.nafnlandeignar}</option>
                            ))}
                        </select>
                        <select
                            value={this.state.selectedFileTypeId}
                            onChange={this.dropdownChangedFiletypes}
                            className="form-select float-right mb-3 d-inline mx-1"
                            aria-label="Filetype select"
                        >
                            {this.state.filetypes.map(type => (
                                <option key={type.skjalgerdid} value={type.skjalgerdid}>{type.nafn}</option>
                            ))}
                        </select>
                    </div>
                    <form className='form-group' onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <label>
                                Skýring*
                                <input
                                    className="form-control"
                                    maxLength="100"
                                    name="description"
                                    type="text"
                                    required
                                    onChange={this.handleInputChange}
                                />
                            </label>
                            {this.state.descriptionError}
                        </div>
                        <div className="form-group">
                            <input
                                type="file"
                                className='form-control'
                                required
                                onChange={this.handlePdfFileChange}
                            />
                            {this.state.pdfFileError && <div className='error-msg'>{this.state.pdfFileError}</div>}
                        </div>
                        <input type="submit" value="Hlaða inn" className="btn btn-success mt-2" />
                    </form>
                </div>
            </Fragment>
        );
    }
}

export default CreatePdf;