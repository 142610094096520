import React, { Component } from 'react';
import Userfront from "@userfront/react";
import Alert from "./Alert";

// Define the Login form component
class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            alertMessage: ""
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setAlertMessage = this.setAlertMessage.bind(this);
    }

    handleInputChange(event) {
        event.preventDefault();
        const target = event.target;
        this.setState({
            [target.name]: target.value,
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        // Reset the alert to empty
        this.setAlertMessage();

        Userfront.login({
            method: "password",
            email: this.state.email,
            password: this.state.password,
        }).catch((error) => {
            this.setAlertMessage(error.message);
        });
    }

    setAlertMessage(message) {
        this.setState({ alertMessage: message });
    }

    render() {
        return (
            <div className="formDiv">
                <h2 className="mb-3">Innskráning</h2>
                <Alert message={this.state.alertMessage} />
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label>
                            Email*
                            <input
                                className="form-control"
                                name="email"
                                type="text"
                                value={this.state.email}
                                onChange={this.handleInputChange}
                            />
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            Password*
                            <input
                                className="form-control"
                                name="password"
                                type="password"
                                value={this.state.password}
                                onChange={this.handleInputChange}
                            />
                        </label>
                    </div>
                    <button className="btn btn-primary" type="submit">Skrá inn</button>
                </form>
            </div>
        );
    }
}

export default LoginForm;